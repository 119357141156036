.modal-delete {
    height: 17rem;
    background-color: white;
    width: 25rem;
    display: flex;
    flex-direction: column;
    margin: 15px;
}

.delete-title {
    background-color: white;
    margin-bottom: 10px;
    text-align: center;
}

.delete-question {
    background-color: white;
    margin-top: 0;
    margin-left: 15px;
}

.red-button {
    height: 3rem;
    background-color: #ff5a5f;
    color: white;
    box-shadow: 4px 3px 5px rgb(133, 117, 117);
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    margin-top: 18px;

}

.grey-button {
    height: 3rem;
    background-color: none;
    border: #51636d 1px solid;
    color: #51636d;
    box-shadow: 4px 3px 5px rgb(133, 117, 117);
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 15px;

}
