p {
    font-family: 'Manjari', sans-serif;
    color: #51636D;

}

#image {
    width: 50%;
    height: auto;

}

.single-spot-body {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    box-sizing: border-box;
    margin: 0;
    justify-content: center;
}

.wrapper {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.loading {
    font-family: 'Manjari', sans-serif;
    color: #51636D;
    font-size: 18px;
}

.grid-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: .5rem;
    flex-basis: 50%;


}

.images {
    display: flex;
    column-gap: .5rem;
}

.small-image {
    box-sizing: border-box;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

}

.reserve-area {
    border: #51636D 1px solid;
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 8px 8px 20px 8px;
    margin-left: 15px;
    border-radius: 15px;
    box-sizing: border-box;
    height: 100%;
}

.top-area-reserve {
    display: flex;
    justify-content: space-between;
    align-items: center;


}

.top-right-reserve {
    display: flex;
    align-items: center;
    align-self: center;
    column-gap: 6px;

}

.small-text {
    font-size: 14px;
}

.reserve-button {
    height: 3rem;
    background-color: #ff5a5f;
    color: white;
    box-shadow: 4px 3px 5px rgb(133, 117, 117);
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
    border: none;
    cursor: pointer;
}

.rating {
    display: flex;
    align-items: center;
    align-self: center;
    column-gap: 2px;
    margin-top: 0;
}

.top-info {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    border-bottom: #51636d6e 1px solid;
    padding-bottom: 25px;

}

.host-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 600px;
    box-sizing: border-box;
}

.host-info p {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;

}

.top-reviews {
    display: flex;

}

.move-up {
    padding-bottom: 9px;
}

.review-names {
    margin-bottom: 0;
}

.review {
    margin-top: 10px;
}

.single-review {
    margin-bottom: 25px;
}

.no-space {
    padding: 0;
}

button {
    height: 2.5rem;
    /* background-color: #51636dbb; */
    color: #51636D;
    box-shadow: 4px 3px 5px rgb(133, 117, 117);
    font-family: 'Comfortaa', cursive;
    font-size: 12px;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    border: #51636D 1px solid;
}

.reviews {
    margin-bottom: 20px;
}

.move-up-less {
    padding-bottom: 3px;
}

.price-stars p {
    margin-bottom: 0;
    margin-top: 0;
}

p.price-spots {
    margin: 0;
}

h2.title-spots {
    margin-bottom: 12px;
}

.dot-from-hell-small {
    margin-bottom: 20px;
    font-size: 35px;
    height: 100%;
    margin-left: 8px;

}

.dot-from-hell {
    font-size: 45px;
    /* change this value to make the dot bigger or smaller */
    margin-bottom: 45px;
    margin-left: 10px;
    margin-right: 10px;
    height: 40px;
}

.small-button {
    height: 2.5rem;
    margin: 10px 10px 0 0px;

}

.bright {
    background-color: #ff5a5f;
    color: white;
    border: none;

}

.no-margin {
    margin-top: 0;
}


@media only screen and (max-width: 760px) {
    .wrapper {
        width: 90%;
    }

    p {
        font-size: 14px;
    }

    h2 {
        font-size: 20px;
    }

    .small-text {
        font-size: 12px;
    }

    .top-info {
        flex-direction: column;
    }

    .reserve-area {
        align-self: center;
        margin-top: 20px;
        width: 100%;

    }

    .host-info {
        width: 100%;
    }
}
