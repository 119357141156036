.image-card {
    box-sizing: border-box;
    width: 362.67px;
    height: 500px;
    border-radius: 25px;
    font-family: 'Manjari', sans-serif;
    color: #51636D;
    margin-bottom: 3rem;
}

img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 25px;
}

.body-spots {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    column-gap: 2rem;
    margin-top: 5rem;
}

.image-box {
    width: 370px;
    height: 370px;
    border: 1px solid #51636d5b;
    background-color: white;
    border-radius: 25px;
}

.spot-info {
    display: flex;
    flex-direction: column;
    align-items: start;
    font-family: 'Manjari', sans-serif;
    color: rgb(81, 99, 109);
    text-decoration: none;


}

.link {
    text-decoration: none;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #51636D;
    color: white;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    width: 120px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.price-stars {
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    margin-bottom: 0;
}

.manage-title {
    margin-top: 30px;
    margin-left: 30px;
}
