@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&family=Comfortaa:wght@300;400;500;600;700&family=Manjari:wght@100;400;700&display=swap');

h1 {
    font-family: 'Manjari', sans-serif;
    font-weight: bold;
    color: #51636D;
}

h2 {
    font-family: 'Manjari', sans-serif;
    color: #51636D;
}

#signupBox {
    display: flex;
    flex-direction: column;
    width: 20rem;
    box-sizing: border-box;
    align-items: center;
    border-radius: 25px;
}

.signupForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 0 25px 25px 25px;
    border-radius: 25px;

}

/* body {
    display: flex;
    justify-content: center;
    background-color: rgba(135, 135, 135, 0.243);
} */

.signuplabel {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    font-family: 'Manjari', sans-serif;
    color: #51636D;


}

#signupbutton {
    height: 3rem;
    background-color: #ff5a5f;
    color: white;
    box-shadow: 4px 3px 5px rgb(133, 117, 117);
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
    border: none;
    cursor: pointer;

}

.input-area {
    background-color: white;
    border-radius: 7px;
    border: #51636d71 1px solid;
    height: 25px;
    font-size: 14px;
    font-family: 'Manjari', sans-serif;
    letter-spacing: 1.5px;

}

.rating {
    display: flex;
    align-items: center;

}

.link {
    margin-bottom: 0;
}
