h3 {
    font-family: 'Manjari', sans-serif;
    color: #51636D;
}


.signup-box {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    border-radius: 25px;
}

.signup-form {
    display: flex;
    flex-direction: column;
    width: 500px;
    box-sizing: border-box;
    padding: 0 25px 25px 25px;
    border-radius: 25px;
}

.signup-label {
    display: flex;
    flex-direction: column;
    font-family: 'Manjari', sans-serif;
    color: #51636D;
}

.input-area-spots {
    background-color: white;
    border-radius: 7px;
    border: #51636d71 1px solid;
    height: 25px;
    font-size: 14px;
    font-family: 'Manjari', sans-serif;
    letter-spacing: 1.5px;
    margin-bottom: 1rem;
}

.input-text-area {
    background-color: white;
    border-radius: 7px;
    border: #51636d71 1px solid;
    height: 70px;
    font-size: 14px;
    font-family: 'Manjari', sans-serif;
    letter-spacing: 1.5px;
    margin-bottom: 1rem;
}

.spot-button {
    height: 3rem;
    background-color: #ff5a5f;
    color: white;
    box-shadow: 4px 3px 5px rgb(133, 117, 117);
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
    border: none;
    cursor: pointer;

}

.city-state {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    margin-bottom: 1rem;
    column-gap: 1rem;
    box-sizing: border-box;
    width: 100%;

}

#city.input-area-spots {
    margin-bottom: 0;
}

#state.input-area-spots {
    margin-bottom: 0;
}

.description-title {
    margin-bottom: 0;
}

.description-info {
    margin-top: 5px;
}

.form-info {
    margin-top: 5px;
    font-weight: 400;
}


.form-subtitle {
    margin: 0;
}

.spot-title {
    margin-bottom: 0;

}

.title-info {
    margin-top: 5px;
}

.price-title {
    margin-bottom: 0;
}

.price-info {
    margin-top: 5px;
}

.errors {
    color: #ff5a5f;
}
