.hidden {
    display: none
}

.logo {
    height: 6rem
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    border-bottom: #51636d70 1px solid;
    padding-left: 5px;

}

.navLi {
    list-style-type: none;
}

.navButton {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: .5rem;
    padding: 5px;
    cursor: pointer;
    border-radius: 25px;
    border: 1px solid #51636D;
    background: transparent;
    width: 5rem;
    height: 2.5rem;
    margin-right: 10px;
}

.fa-solid.fa-bars {
    font-size: 15px;
    color: #51636D;

}

.fas.fa-user-circle {
    font-size: 25px;
    color: #51636D;
}

.profile-dropdown {
    list-style-type: none;
    cursor: pointer;
    font-family: 'Manjari', sans-serif;
    font-size: 20px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    position: absolute;
    right: 20px;
    top: 4.2rem;
    width: 12rem;
    color: #51636D;
    background-color: rgb(255, 255, 255);
    box-shadow: 5px 5px 5px rgb(198, 198, 198);


}

.log-in {
    background-color: white;
    margin-bottom: 8px;
}

.sign-up {
    background-color: white;
}

.logout-button {
    height: 3rem;
    background-color: #ff5a5f;
    color: white;
    box-shadow: 4px 3px 5px rgb(133, 117, 117);
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
    border: none;
    margin: 0 auto;
    cursor: pointer;
    width: 90%;
}

.log-in-button {
    background-color: white;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}


.create-spot-text {
    cursor: pointer;
    font-family: 'Manjari', sans-serif;
    text-decoration: none;
    list-style-type: none;
    color: #51636D;
}

.nav-top-right {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.log-in a {
    text-decoration: none;
    list-style-type: none;
    color: #51636D;
    background-color: white;
}

@media only screen and (max-width: 760px) {
    .logo {
        height: 5rem;
    }

    .navButton {
        height: auto;
        width: fit-content;
        padding: 5px 10px;
        margin-right: 0;
    }

    .fa-solid.fa-bars {
        font-size: 15px;
    }

    .fas.fa-user-circle {
        font-size: 25px;
    }

    .create-spot-text {
        font-size: 12px;
    }

    .navbar {
        height: 6rem;
        padding-left: 0;
    }
}
