h2 {
    font-family: 'Manjari', sans-serif;
    font-weight: bold;
    color: #51636D;
}

.review-form {
    display: flex;
    flex-direction: column;
    width: 30rem;
    box-sizing: border-box;
    padding: 0 25px 25px 25px;
    border-radius: 25px;
}


.review-label {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    font-family: 'Manjari', sans-serif;
    color: #51636D;
}


.review-button {
    height: 3rem;
    background-color: #ff5a5f;
    color: white;
    box-shadow: 4px 3px 5px rgb(133, 117, 117);
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
    border: none;
    cursor: pointer;
}

.review-input {
    background-color: white;
    border-radius: 7px;
    border: #51636d71 1px solid;
    height: 25px;
    font-size: 14px;
    font-family: 'Manjari', sans-serif;
    letter-spacing: 1.5px;
    padding-top: 8px;
}

.long-text {
    height: 80px;
}

.rating-input {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin: 10px 0;

}

.rating-input div {
    cursor: pointer;
}

.empty {
    color: #51636d7e;
}

.filled {
    color: #51636D;
}

@media (max-width:740px) {
    .review-form {
        width: 20rem;
    }

}
