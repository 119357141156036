.upcoming-trip-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.trips-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
}

.trips-wrapper h1 {
    width: 100%;
}

.upcoming-trip-div {
    padding: 0 1rem;
}

/* this div includes the title of the section, bookings-container and all the booking tiles  */
.past-trip-div {
    padding: 0 1rem;
}

/* this is one level in the past-trip-div and contains all the booking tiles */
.bookings-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns by default */
}

/* each individual card */
.booking-tile {
    display: flex;
    justify-content: space-between;

}

/* Media query for screens between 1150px and 740px */
@media (max-width: 1150px) {
    .bookings-container {
        grid-template-columns: repeat(2, 1fr);
        /* 2 columns for medium-sized screens */
    }
}

/* Media query for screens less than 740px */
@media (max-width: 740px) {
    .bookings-container {
        grid-template-columns: repeat(1, 1fr);
        /* 1 column for small screens */
    }

    .trips-wrapper {
        align-items: center;
        padding: 0;
    }

    .trips-wrapper h1 {
        border-bottom: 1px solid #51636d71;
        margin: 0;
        padding: .5rem 0;
    }
}
